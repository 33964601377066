import React from "react";
import { Typography } from "@mui/material";
import FirebaseImageFetcher from "../components/FirebaseImageFetcher";
import AutoWidthCodeBlock from "../components/AutoWidthCodeBlock";

const BigQueryStocks = () => {
  const installYFinance = `$ pip install yfinance`;
  const processDataFunc = `
  import yfinance as yf
  import pandas as pd
  from datetime import datetime, timedelta
  
  def process_data(tickers):
      # Get today's date
      end_date = datetime.today().strftime('%Y-%m-%d')
  
      # Calculate the start date (6 months ago)
      start_date = (datetime.today() - timedelta(days=365)).strftime('%Y-%m-%d')
  
      # Create an empty DataFrame with the columns you need
      company_data = pd.DataFrame(columns=['Open', 'High', 'Low', 'Close', 'Adj Close', 'Volume', 'Ticker'])
  
      # Loop through each ticker and get the historical data
      for ticker in tickers:
          try:
              stock_data = yf.download(ticker, start=start_date, end=end_date)
              stock_data['Ticker'] = ticker  # Add the ticker as a column
              company_data = pd.concat([company_data, stock_data], sort=False)
          except Exception as e:
              print(f"Failed to retrieve data for {ticker}: {e}")
  
      company_data.reset_index(inplace=True)
      company_data = company_data.rename(columns={'index': 'Date'})
  
      return company_data
  `;

  const createBigQueryTable = `
  def create_bigquery_table(dataset_id, table_id):
    # Set the Google Cloud project ID
    project_id = "PROJECT ID"
    os.environ["GOOGLE_CLOUD_PROJECT"] = project_id

    # Define the schema for the table
    schema = [
        bigquery.SchemaField("date", "DATE", mode="NULLABLE"),
        bigquery.SchemaField("open", "FLOAT", mode="NULLABLE"),
        bigquery.SchemaField("high", "FLOAT", mode="NULLABLE"),
        bigquery.SchemaField("low", "FLOAT", mode="NULLABLE"),
        bigquery.SchemaField("close", "FLOAT", mode="NULLABLE"),
        bigquery.SchemaField("adj_close", "FLOAT", mode="NULLABLE"),
        bigquery.SchemaField("volume", "INTEGER", mode="NULLABLE"),
        bigquery.SchemaField("ticker", "STRING", mode="NULLABLE"),
    ]

    # Initialize a BigQuery client
    client = bigquery.Client(project=project_id)

    # Define the table reference
    table_ref = client.dataset(dataset_id).table(table_id)

    # Define the table schema
    table = bigquery.Table(table_ref, schema=schema)

    # Create the table
    client.create_table(table)

    print(f"Table {table_id} created with schema:")
    for field in table.schema:
        print(f"\t{field.name}: {field.field_type}")
  `;

  const insertToBigQuery = `
  def insert_bigquery(dataset_id, table_id, csv_file):
    # Set the Google Cloud project ID
    project_id = "PROJECT ID"
    os.environ["GOOGLE_CLOUD_PROJECT"] = project_id

    # Create a BigQuery client
    client = bigquery.Client()

    # Create a BigQuery table reference
    table_ref = client.dataset(dataset_id).table(table_id)
    table = client.get_table(table_ref)

    # Specify the job configuration
    job_config = bigquery.LoadJobConfig(
        source_format=bigquery.SourceFormat.CSV,
        skip_leading_rows=1,
        autodetect=True
    )

    # Load data from the CSV file into the BigQuery table
    with open(csv_file, 'rb') as source_file:
        job = client.load_table_from_file(
            source_file,
            table_ref,
            job_config=job_config
        )

    # Wait for the job to complete
    job.result()

    print('Data inserted successfully.')
    `;

  const deleteBigQuery = `
  def delete_bigquery_table(dataset_id, table_id):
    # Set the Google Cloud project ID
    project_id = "PROJECT ID"
    os.environ["GOOGLE_CLOUD_PROJECT"] = project_id

    # Create a BigQuery client
    client = bigquery.Client()

    # Construct the table reference
    table_ref = client.dataset(dataset_id).table(table_id)

    # Get the schema of the table
    table = client.get_table(table_ref)

    # Delete the old table
    client.delete_table(table)

    print(f"Table {table_id} deleted")
    `;

  const finalScript = `
  tickers = ["AAPL"]
  dataset_id = 'StockPortfolio'
  table_id = 'history'
  csv_file = 'company_data.csv'

  # Process the data
  company_data = process_data(tickers)

  # Save the data to a CSV file
  company_data.to_csv(csv_file, index=False)

  # Delete old BigQuery table
  delete_bigquery_table(dataset_id, table_id)

  # Create new BigQuery table
  create_bigquery_table(dataset_id, table_id)

  # Insert the data into BigQuery
  insert_bigquery(dataset_id, table_id, csv_file)
  `;
  return (
    <>
      <div>
        <Typography variant="h2">Stock Data Storage with BigQuery</Typography>
        <Typography variant="h5">
          Components used: BigQuery, Python, yfinance API
        </Typography>
        <Typography>
          In this project, I will demonstrate how to automate the process of
          retrieving a year's stock price data for a specific company using
          Python. Any API can be utilized for this purpose. Following data
          retrieval, we will perform necessary transformations and store the
          results in a .csv file. Additionally, we will store the data in a
          BigQuery table. Creation of a BigQuery account is required for this
          project. Python will be used to authenticate with the Google account.
        </Typography>
      </div>
      <div>
        <FirebaseImageFetcher
          imgLink={"projects/big_query_stocks/system-diagram.png"}
          maxHeight={400}
        />
      </div>
      <div>
        <Typography variant="h4">Install yfinance</Typography>
        <Typography>
          This{" "}
          <a
            href="https://pypi.org/project/yfinance/"
            target="_blank"
            rel="noopener noreferrer"
          >
            python module
          </a>{" "}
          allows user to download market data from Yahoo! Finance API. By
          providing the company's ticker, user can get information about the
          company's historical stock price.
        </Typography>
        <AutoWidthCodeBlock
          language={"bash"}
          code={installYFinance}
          widthPercentage={100}
        />
        <Typography variant="h4">Pull data in python using ticker</Typography>
        <Typography>
          In Python, we define a function called process_data that takes a
          single input: a list of companies' ticker symbols. In this function,
          we will hardcode the download of data from the last 365 days by
          specifying the start and end dates. The downloaded data will be stored
          in a DataFrame, which will serve as the output of this function.
        </Typography>
        <AutoWidthCodeBlock
          language={"python"}
          code={processDataFunc}
          widthPercentage={100}
        />
        <Typography variant="h4">
          Define BigQuery Handler Functions in Python
        </Typography>
        <Typography>
          Next, the big_query.py script manages the interaction with BigQuery,
          providing functions to create, delete, and insert data into a
          specified table within a dataset. Leveraging the Google Cloud Client
          Library for Python (google-cloud-bigquery), this component ensures
          seamless communication with BigQuery, enabling the pipeline to
          efficiently manage the data storage aspect of the process.See:{" "}
          <a
            href="https://cloud.google.com/python/docs/reference/bigquery/latest"
            target="_blank"
            rel="noopener noreferrer"
          >
            documentation
          </a>
          . <br /> The first function we will create is called
          create_bigquery_table. As the name suggests, it will create a table in
          BigQuery. Please note that you need to first set up the project and
          dataset in BigQuery. The table's schema will match the column
          structure of the DataFrame.
        </Typography>
        <AutoWidthCodeBlock
          language={"python"}
          code={createBigQueryTable}
          widthPercentage={100}
        />
        <Typography>
          Next, we will create a function that will insert our stock data into
          BigQuery table
        </Typography>
        <AutoWidthCodeBlock
          language={"python"}
          code={insertToBigQuery}
          widthPercentage={100}
        />
        <Typography>
          Lastly, we will create a function to delete an existing table with the
          same name. We choose to delete the table each time we run this script
          because we are not concerned with maintaining previous data; we only
          want to keep data from the past 365 days. Additionally, since I'm
          using a free BigQuery account, running a "DROP" operation is not
          allowed in the free tier account. Thus, dropping the table entirely
          and starting fresh is the only viable approach.
        </Typography>
        <AutoWidthCodeBlock
          language={"python"}
          code={deleteBigQuery}
          widthPercentage={100}
        />
        <Typography variant="h4">Putting in Together</Typography>
        <AutoWidthCodeBlock
          language={"python"}
          code={finalScript}
          widthPercentage={100}
        />
        <Typography>
          In the main script, main.py, we orchestrate the entire data pipeline.
          We specify the list of tickers to process, along with the names of the
          BigQuery dataset and table where the data will be stored. The script
          first processes the data using process_data.py, saving it to a CSV
          file. It then manages the lifecycle of the BigQuery table, deleting
          any existing table with the same name and creating a new table with
          the specified schema. Finally, it inserts the data from the CSV file
          into the newly created BigQuery table, completing the pipeline.
          <br />
          Looking ahead, there are several areas where our pipeline can be
          further enhanced. Implementing robust error handling mechanisms,
          enabling incremental updates to the BigQuery table for new data, and
          adding logging to track the pipeline's progress and status are just a
          few avenues for improvement. Nevertheless, our current pipeline stands
          as a testament to the power of Python, Pandas, Yahoo Finance API, and
          Google BigQuery in orchestrating a seamless and efficient data
          processing workflow for historical stock price data.
        </Typography>
      </div>
    </>
  );
};

export { BigQueryStocks };
