import React from "react";
import { styled } from "@mui/material";
import { NavLink } from "react-router-dom";

const CustomLink = styled(NavLink)(({ theme }) => ({
  color: "inherit",
  textDecoration: "none",
  "&.active": {
    fontWeight: "bold",
  },
  margin: "0 1rem",
}));

const CustomNav = styled("nav")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "1rem",
}));

const CustomList = styled("ul")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  listStyle: "none",
  margin: 0,
}));

export default function Navbar() {
  return (
    <CustomNav>
      <CustomLink to="/">Teguh Sam</CustomLink>
      <CustomList>
        <li>
          <CustomLink to="/" activeClassName="active">
            Home
          </CustomLink>
        </li>
        {/* <li>
          <CustomLink to="/projects" activeClassName="active">
            Projects
          </CustomLink>
        </li> */}
      </CustomList>
    </CustomNav>
  );
}
