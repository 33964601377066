// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDITZYbjKb2GypoVmCFYQbiR0zctsYkilg",
  authDomain: "photography-portfolio-c0447.firebaseapp.com",
  projectId: "photography-portfolio-c0447",
  storageBucket: "photography-portfolio-c0447.appspot.com",
  messagingSenderId: "44572229781",
  appId: "1:44572229781:web:6c08353adbc9fa35095b9c",
  measurementId: "G-GCP5LPDY9X",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
