import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import { storage } from "./firebase";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { createTheme, ThemeProvider } from "@mui/material";
import { About } from "./pages/about";
import { Photography } from "./pages/photography";
import { Helmet } from "react-helmet";
import { Projects } from "./pages/projects";
import { BigQueryStocks } from "./pages/bigQueryStocks";

function App() {
  const [imageList, setImageList] = useState([]);
  const imageListRef = ref(storage, "portfolio");
  const theme = createTheme();

  useEffect(() => {
    listAll(imageListRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImageList((prev) => [...prev, url]);
        });
      });
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Teguh Samudra Portfolio Data Engineer"
        />
        <title>Teguh Sam Portfolio</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <Navbar />
        <div>
          <Routes>
            <Route path="/" element={<About />} />
            <Route
              path="/photography"
              element={<Photography imgList={imageList} />}
            />
            {/* <Route path="/projects" element={<Projects />} /> */}
            <Route
              path="/projects/big-query-stocks"
              element={<BigQueryStocks />}
            />
          </Routes>
        </div>
      </ThemeProvider>
    </>
  );
}

export default App;
