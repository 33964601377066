import React from "react";
import { Typography, List, Chip, Card, CardContent } from "@mui/material";
import Colours from "../components/Colours";

const skillList = [
  { name: "Python", type: "programming" },
  { name: "Go (Golang)", type: "programming" },
  { name: "JavaScript", type: "programming" },
  { name: "TypeScript", type: "programming" },
  { name: "R", type: "programming" },
  { name: "SQL", type: "programming" },
  { name: "Postgres", type: "data" },
  { name: "MySQL", type: "data" },
  { name: "ETL", type: "data" },
  { name: "BigQuery", type: "data" },
  { name: "React", type: "ui_viz" },
  { name: "Power BI", type: "ui_viz" },
  { name: "Tableau", type: "ui_viz" },
  { name: "D3.js", type: "ui_viz" },
  { name: "Version Control (GitLab/GitHub)", type: "other" },
  { name: "Docker", type: "other" },
  { name: "Grafana", type: "other" },
];

const colourList = [
  { type: "programming", colour: Colours.red },
  { type: "other", colour: Colours.gray },
  { type: "data", colour: Colours.blue },
  { type: "ui_viz", colour: Colours.beige },
];

const Skills = () => {
  return (
    <Card sx={{ backgroundColor: Colours.blueLightTint }}>
      <CardContent>
        <Typography variant="h6">Skills</Typography>
        <List sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          {skillList.map((skill, index) => {
            const colour = colourList.find(
              (item) => item.type === skill.type
            )?.colour;
            return (
              <Chip
                key={index}
                label={skill.name}
                style={{ backgroundColor: colour, color: "white" }}
              />
            );
          })}
        </List>
      </CardContent>
    </Card>
  );
};

export { Skills };
