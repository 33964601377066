import React from "react";
import { Typography, Card, CardContent } from "@mui/material";
import Colours from "../components/Colours";
import { Link } from "react-router-dom";

const Projects = () => (
  <Card sx={{ backgroundColor: Colours.blueLightTint }}>
    <CardContent>
      <Typography variant="h6">Projects</Typography>
      <Link to="/projects/big-query-stocks">
        Stock Data Storage with BigQuery
      </Link>
    </CardContent>
  </Card>
);

export { Projects };
