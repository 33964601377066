import React, { useState, useEffect } from "react";
import { storage } from "../firebase";
import { ref, getDownloadURL } from "firebase/storage";

const FirebaseImageFetcher = ({ imgLink, maxHeight, maxWidth }) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const storageRef = ref(storage, imgLink);
        const url = await getDownloadURL(storageRef);
        setImageUrl(url);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();
  }, [imgLink]);

  if (!imageUrl) {
    return null;
  }

  return (
    <img src={imageUrl} alt="Firebase Image" style={{ maxHeight, maxWidth }} />
  );
};

export default FirebaseImageFetcher;
