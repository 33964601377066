import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { Typography, Card, CardContent } from "@mui/material";
import Colours from "../components/Colours";

const GalleryImage = styled(LazyLoadImage)({
  maxHeight: 700,
  maxWidth: 900,
  margin: "1px",
});

const Photography = (imgList) => {
  return imgList.imgList.map((url) => {
    return <GalleryImage src={url} />;
  });
};

const PhotographyLink = () => (
  <Card sx={{ backgroundColor: Colours.blueLightTint }}>
    <CardContent>
      <Typography variant="h6">Other</Typography>
      <p>
        In my spare time, I like to do photography. You can check this out here:
      </p>
      <Link to="/photography">My photography portfolio</Link>
    </CardContent>
  </Card>
);

export { Photography, PhotographyLink };
