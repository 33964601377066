import React from "react";
import { useEffect, useState } from "react";
import { storage } from "../firebase";
import { ref, getDownloadURL } from "firebase/storage";
import { Grid, styled } from "@mui/material";
import { SummaryText } from "./aboutText";
import { Skills } from "./skill";
import { Education } from "./education";
import { PhotographyLink } from "./photography";
import { Projects } from "./projects";

const Picture = styled("img")({
  maxHeight: 500,
  maxWidth: "100%",
  float: "right",
});

const About = () => {
  const imgRef = ref(storage, "assets/about_picture.jpg");
  const [image, setImage] = useState();
  useEffect(() => {
    async function fetchData() {
      const response = await getDownloadURL(imgRef);
      setImage(response);
    }
    fetchData();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Picture src={image} />
      </Grid>
      <Grid item xs={6}>
        <SummaryText />
        <Grid item sx={{ marginTop: 2 }}>
          <Skills />
        </Grid>
        <Grid item sx={{ marginTop: 2 }}>
          <Projects />
        </Grid>
        <Grid item sx={{ marginTop: 2 }}>
          <Education />
        </Grid>
        <Grid item sx={{ marginTop: 2 }}>
          <PhotographyLink />
        </Grid>
      </Grid>
    </Grid>
  );
};

export { About };
