import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";

const AutoWidthCodeBlock = ({ language, code }) => {
  return (
    <div style={{ width: "80%", overflowX: "auto" }}>
      <SyntaxHighlighter
        language={language}
        style={atomDark}
        customStyle={{
          width: "100%",
          whiteSpace: "pre-wrap",
          fontSize: "12px",
        }}
      >
        {code}
      </SyntaxHighlighter>
    </div>
  );
};

export default AutoWidthCodeBlock;
