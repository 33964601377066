import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import Colours from "../components/Colours";

const SummaryText = () => (
  <Card sx={{ backgroundColor: Colours.blueLightTint }}>
    <CardContent>
      <Typography variant="body1">
        My name is Teguh. <br />I am a Data and Analytics Engineer currently
        located in Vancouver. I have 4 years of work experience working in data
        engineering. I am also working as a fullstack software developer in my
        current company using React, Go-based backend server, and Postgres.
      </Typography>
    </CardContent>
  </Card>
);

export { SummaryText };
